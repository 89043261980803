<template>
  <v-container
      id="regular-tables"
      fluid
      tag="section"
  >
    <v-row>
      <v-col cols="auto">
        <v-list nav color="transparent">
          <v-list-item
              v-for="(item, i) in menuItems"
              :key="i"
              :to="item.to"
              exact
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text" class="item"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col>
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Settings',
  data() {
    return {
      menuItems: [
        { text: 'Profile', icon: 'mdi-account', to: '/dashboard/settings'},
        { text: 'Shipping accounts', icon: 'mdi-cube-outline', to: '/dashboard/settings/accounts'},
      ]
    }
  }
}
</script>

<style>
  .item {
    padding-right: 55px;
  }
</style>